var DRIVE_ID = "b!CJAc-QIJ-UeRHRXRwb49rz4Mn5Lm-69FuP6nhdxmQ721sPd5rSFEQrUuXpBmoPSG";

let excelVariables = {
	  SETTINGS_SHEET_ID: "01KVWBY3DIEALLKMRO6VEZ4TRTJ4VEQY5H", //GrimesChartsSettings.xlsx
	  settingsRange: "A1:C20",
	  settingsSheet: "Settings",
}

var sessionId;

var msalConfig = {
	auth: {
		clientId: "a087f638-aca1-4b2b-bd2b-e75d2a11d123",
		authority: "https://login.microsoftonline.com/d0e57b79-4527-461c-ae01-38a8698f53c0"
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true
	}
};

const DATA_FORMAT = "MM/DD/YYYY";

const DATES_SELECT_GROUP = "SELECTORS";
const DATES_CUSTOM_GROUP = "CUSTOM";
const RECALCULATE = true;
const NOT_RECALCULATE = false;

var datesList = [];

var dataDateFrom = null;
var dataDateTo = null;

var annualReturnsLength = 0;
var tableStatisticsLength = 0;

let statisticsData = null;
let maxDrawdownData = null;

let startingBalanceControl = null;

let cashFlowGlobalStocksControl = null;
let cashFlow6040StaticControl = null;
let cashFlowTacticalEqutiesControl = null;
let cashFlow8020TacticalControl = null;

let inflationGlobalStocksControl = null;
let inflation6040StaticControl = null;
let inflationTacticalEqutiesControl = null;
let inflation8020TacticalControl = null;

let globalStockGlobalStocksControl = null;
let globalStock6040StaticControl = null;
let globalStockTacticalEqutiesControl = null;
let globalStock8020TacticalControl = null;

let bondGlobalStocksControl = null;
let bond6040StaticControl = null;
let bondTacticalEqutiesControl = null;
let bond8020TacticalControl = null;

let selectFromDateEditableControl = null;
let selectToDateEditableControl = null;

let colors = {
	pieChart1 : "#687593",
	pieChart2 : "#CDCDCD",

	lineChart1: "#1f497d", //'rgb(31, 73, 125)',
	lineChart2: "#c0504d", // 'rgb(192, 80, 77)',
	lineChart3: "#8064a2", // rgb(128, 100, 162),
	lineChart4: "#4bacc6"  //'rgb(75, 172, 198)',

}

let toggleState = {
	series : [true, true, true, true]
}

var chartData = {
	dates: [],
	dataSeries1: [],
	dataSeries2: [],
	dataSeries3: [],
	dataSeries4: [],
	titleChart: "Portfolio Illustrator - Diversification and Tactical Investment Management",
	titleSerie1: "Global Stocks",
	titleSerie2: "60/40 Static",
	titleSerie3: "Tactical Equties",
	titleSerie4: "80/20 Tactical",

	yAxesLabelString: "Value $"
};

var chart2Data = {
	dates: [],
	dataSeries1: [],
	dataSeries2: [],
	dataSeries3: [],
	dataSeries4: [],
	titleChart: "Portfolio Drawdown",
	titleSerie1: "Global Stocks",
	titleSerie2: "60/40 Static",
	titleSerie3: "Tactical Equties",
	titleSerie4: "80/20 Tactical",

	yAxesLabelString: "%"
};


let graphVariables = {

	DATA_SHEET_ID: "",

	marketConditionsRange: "",
	marketConditionsSheet: "",

	datesRange: "",
	datesSheet: "",

	startingBalanceRange: "",
	startingBalanceSheet: "",

	settingsRange: "",
	settingsSheet: "",

	cashflowRange: "",
	cashflowSheet: "",

	inflationRange: "",
	inflationSheet: "",

	marketExposureRange: "",
	marketExposureSheet: "",

	yearlySeriesRange: "",
	yearlySeriesSheet: "",

	statisticsRange: "",
	statisticsSheet: "",

	maxDrawdownRange: "",
	maxDrawdownSheet: "",

	datesListRange: "",
	datesListSheet: "",

	chart1Serie1Range: "",
	chart1Serie1Sheet: "",

	chart1Serie2Range: "",
	chart1Serie2Sheet: "",

	chart1Serie3Range: "",
	chart1Serie3Sheet: "",

	chart1Serie4Range: "",
	chart1Serie4Sheet: "",

	chart2Serie1Range: "",
	chart2Serie1Sheet: "",

	chart2Serie2Range: "",
	chart2Serie2Sheet: "",

	chart2Serie3Range: "",
	chart2Serie3Sheet: "",

	chart2Serie4Range: "",
	chart2Serie4Sheet: "",
}

function GetBaseUrl() {
	  return "https://graph.microsoft.com/v1.0/drives/" + DRIVE_ID + "/items/" + graphVariables.DATA_SHEET_ID;
}

function GetDataRangeBaseUrl() {
	return GetBaseUrl() + "/workbook/worksheets/";
}

function GetEndPointUrl(endpoint) {
	let endPointUrl = "";
	switch (endpoint) {
		case "createSessionEndpoint":
			endPointUrl = GetBaseUrl() + "/workbook/createSession";
			break;
		case "marketConditionsEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.marketConditionsSheet 	+ "/range(address='" + graphVariables.marketConditionsRange + "')";
			break;
		case "datesEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.datesSheet 			+ "/range(address='" + graphVariables.datesRange 			+ "')";
			break;
		case "startingBalanceEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.startingBalanceSheet 	+ "/range(address='" + graphVariables.startingBalanceRange 	+ "')";
			break;
		case "settingsEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.settingsSheet 			+ "/range(address='" + graphVariables.settingsRange 		+ "')";
			break;
		case "cashflowEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.cashflowSheet 			+ "/range(address='" + graphVariables.cashflowRange 		+ "')";
			break;
		case "marketExposureEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.marketExposureSheet	+ "/range(address='" + graphVariables.marketExposureRange 	+ "')";
			break;
		case "yearlySeriesEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.yearlySeriesSheet 		+ "/range(address='" + graphVariables.yearlySeriesRange 	+ "')";
			break;
		case "statisticsEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.statisticsSheet 		+ "/range(address='" + graphVariables.statisticsRange 		+ "')";
			break;
		case "maxDrawdownEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.maxDrawdownSheet		+ "/range(address='" + graphVariables.maxDrawdownRange 		+ "')";
			break;
		case "datesListEndpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.datesListSheet 		+ "/range(address='" + graphVariables.datesListRange 		+ "')";
			break;
		case "chart1Serie1Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart1Serie1Sheet 		+ "/range(address='" + graphVariables.chart1Serie1Range 	+ "')";
			break;
		case "chart1Serie2Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart1Serie2Sheet 		+"/range(address='"	 + graphVariables.chart1Serie2Range 	+ "')";
			break;
		case "chart1Serie3Endpoint":
				endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart1Serie3Sheet 		+"/range(address='"	 + graphVariables.chart1Serie3Range 	+ "')";
			break;
		case "chart1Serie4Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart1Serie4Sheet 		+"/range(address='"  + graphVariables.chart1Serie4Range 	+ "')";
			break;
		case "chart2Serie1Endpoint":
				endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart2Serie1Sheet 		+ "/range(address='" + graphVariables.chart2Serie1Range 	+ "')";
				break;
		case "chart2Serie2Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart2Serie2Sheet 		+"/range(address='"	 + graphVariables.chart2Serie2Range 	+ "')";
			break;
		case "chart2Serie3Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart2Serie3Sheet 		+"/range(address='"	 + graphVariables.chart2Serie3Range 	+ "')";
			break;
		case "chart2Serie4Endpoint":
			endPointUrl = GetDataRangeBaseUrl() + graphVariables.chart2Serie4Sheet 		+"/range(address='"  + graphVariables.chart2Serie4Range 	+ "')";
			break;
	}
	return endPointUrl;
}

let SETTINGS_BASE_URL = "https://graph.microsoft.com/v1.0/drives/" + DRIVE_ID + "/items/" + excelVariables.SETTINGS_SHEET_ID;
let SETTINGS_RANGE_BASE_URL = SETTINGS_BASE_URL + "/workbook/worksheets/";
let excelSettingsEndpoint =	SETTINGS_RANGE_BASE_URL + excelVariables.settingsSheet 	+ "/range(address='" + excelVariables.settingsRange + "')";


// create a request object for login or token request calls
// In scenarios with incremental consent, the request object can be further customized
var requestObj = {
	scopes: ["user.read", "files.read", "files.readwrite"]
};

var myMSALObj = new Msal.UserAgentApplication(msalConfig);

// Register Callbacks for redirect flow
// myMSALObj.handleRedirectCallbacks(acquireTokenRedirectCallBack, acquireTokenErrorRedirectCallBack);
myMSALObj.handleRedirectCallback(authRedirectCallBack);

function signIn() {
	myMSALObj
		.loginPopup(requestObj)
		.then(function(loginResponse) {
			//Successful login
			showWelcomeMessage();
			//Call MS Graph using the token in the response
			acquireTokenPopupAndCallMSGraph();
		})
		.catch(function(error) {
			//Please check the console for errors
			console.error(error);
		});
}

function signOut() {
	myMSALObj.logout();
}

function acquireTokenPopupAndCallMSGraph() {
	//Always start with acquireTokenSilent to obtain a token in the signed in user from cache
	myMSALObj
		.acquireTokenSilent(requestObj)
		.then(function(tokenResponse) {
			GetSourceData(tokenResponse.accessToken);
		})
		.catch(function(error) {
			console.error(error);
			// Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
			// Call acquireTokenPopup(popup window)
			if (requiresInteraction(error.errorCode)) {
				myMSALObj
					.acquireTokenPopup(requestObj)
					.then(function(tokenResponse) {
						GetSourceData(tokenResponse.accessToken);
					})
					.catch(function(error) {
						console.error(error);
					});
			}
		});
}

function showWelcomeMessage() {
	var divWelcome = document.getElementById("WelcomeMessage");
	divWelcome.innerHTML = myMSALObj.getAccount().userName;
	var loginbutton = document.getElementById("SignIn");
	loginbutton.innerHTML = "Logout";
	loginbutton.onclick = signOut;
}

//This function can be removed if you do not need to support IE
function acquireTokenRedirectAndCallMSGraph() {
	//Always start with acquireTokenSilent to obtain a token in the signed in user from cache
	myMSALObj
		.acquireTokenSilent(requestObj)
		.then(function(tokenResponse) {
			GetSourceData(tokenResponse.accessToken);
		})
		.catch(function(error) {
			console.error(error);
			// Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
			// Call acquireTokenRedirect
			if (requiresInteraction(error.errorCode)) {
				myMSALObj.acquireTokenRedirect(requestObj);
			}
		});
}

function authRedirectCallBack(error, response) {
	if (error) {
		console.error(error);
	} else {
		if (response.tokenType === "access_token") {
			GetSourceData(response.accessToken);
		} else {
			console.info("token type is:" + response.tokenType);
		}
	}
}

function requiresInteraction(errorCode) {
	if (!errorCode || !errorCode.length) {
		return false;
	}
	return errorCode === "consent_required" || errorCode === "interaction_required" || errorCode === "login_required";
}

document.addEventListener("DOMContentLoaded", main);

function main() {
	// Browser check variables
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var msie11 = ua.indexOf("Trident/");
	var msedge = ua.indexOf("Edge/");
	var isIE = msie > 0 || msie11 > 0;
	var isEdge = msedge > 0;

	DefineInputControls();

	ChangeElementVisibility("alertMessage", false);
	ChangeElementVisibility("LoadSpinner", false);
	ChangeElementVisibility("LoadSpinner2", false);

	ChangeDatesRangeSelectorsVisibility(DATES_SELECT_GROUP);

	//If you support IE, our recommendation is that you sign-in using Redirect APIs
	//If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check

	// can change this to default an experience outside browser use
	var loginType = isIE ? "REDIRECT" : "POPUP";
	// runs on page load, change config to try different login types to see what is best for your application
	if (loginType === "POPUP") {
		document.getElementById("SignIn").onclick = signIn;

		document.getElementById("UpdateRemoteDatesButton").onclick = RecalculateData;

		document.getElementById("UpdateRemoteSettingsButton").onclick = RecalculateData;

		document.querySelector('#selectMarketConditions').onchange = SelectMarketConditionsChange;

		if (myMSALObj.getAccount()) {
			// avoid duplicate code execution on page load in case of iframe and popup window.
			showWelcomeMessage();
			acquireTokenPopupAndCallMSGraph();
		}
	} else if (loginType === "REDIRECT") {
		document.getElementById("SignIn").onclick = function() {
			myMSALObj.loginRedirect(requestObj);
		};

		if (myMSALObj.getAccount() && !myMSALObj.isCallback(window.location.hash)) {
			// avoid duplicate code execution on page load in case of iframe and popup window.
			showWelcomeMessage();
			acquireTokenRedirectAndCallMSGraph();
		}
	} else {
		console.error("Please set a valid login type");
	}
}


function DefineInputControls() {
	startingBalanceControl = DefineInputIntegerControl('#startingBalance');

	cashFlowGlobalStocksControl = DefineInputPercentageControl('#cashFlowGlobalStocks');
	cashFlow6040StaticControl = DefineInputPercentageControl('#cashFlow6040Static');
	cashFlowTacticalEqutiesControl = DefineInputPercentageControl('#cashFlowTacticalEquties');
	cashFlow8020TacticalControl = DefineInputPercentageControl('#cashFlow8020Tactical');

	inflationGlobalStocksControl = DefineInputPercentageControl('#inflationGlobalStocks');
	inflation6040StaticControl = DefineInputPercentageControl('#inflation6040Static');
	inflationTacticalEqutiesControl = DefineInputPercentageControl('#inflationTacticalEquties');
	inflation8020TacticalControl = DefineInputPercentageControl('#inflation8020Tactical');

	globalStockGlobalStocksControl = DefineInputPercentageIntegerControl('#globalStockGlobalStocks');
	globalStock6040StaticControl = DefineInputPercentageIntegerControl('#globalStock6040Static');
	globalStockTacticalEqutiesControl = DefineInputPercentageIntegerControl('#globalStockTacticalEquties');
	globalStock8020TacticalControl = DefineInputPercentageIntegerControl('#globalStock8020Tactical');

	bondGlobalStocksControl = DefineInputPercentageIntegerControl('#bondGlobalStocks');
	bond6040StaticControl = DefineInputPercentageIntegerControl('#bond6040Static');
	bondTacticalEqutiesControl = DefineInputPercentageIntegerControl('#bondTacticalEquties');
	bond8020TacticalControl = DefineInputPercentageIntegerControl('#bond8020Tactical');

	selectFromDateEditableControl = DefineInputDateControl('#selectFromDateEditable');
	selectToDateEditableControl = DefineInputDateControl('#selectToDateEditable');
}

function PopulateDatesControls() {
	console.info("Populate dates on select controls");

	//console.table(datesList);

	const dayjs = require("dayjs");

	var selectFromDate = document.getElementById("selectFromDate");
	var selectToDate = document.getElementById("selectToDate");

	var indexFrom = 0;
	var indexTo = 0;

	for (i = datesList.length - 1; i >= 0; i--) {
		let dateStr = dayjs(datesList[i]).format(DATA_FORMAT);

		if (dayjs(datesList[i]).isSame(dayjs(dataDateFrom))) {
			indexFrom = datesList.length - 1 - i;
		}

		if (dayjs(datesList[i]).isSame(dataDateTo)) {
			indexTo = datesList.length - 1 - i;
		}

		var optionFrom = document.createElement("option");
		optionFrom.text = dateStr;
		selectFromDate.add(optionFrom);

		var optionTo = document.createElement("option");
		optionTo.text = dateStr;
		selectToDate.add(optionTo);
	}

	selectFromDate.selectedIndex = indexFrom;
	selectToDate.selectedIndex = indexTo;

	selectFromDateEditableControl.setRawValue(GetControlSelectedElement("selectFromDate"));
	selectToDateEditableControl.setRawValue(GetControlSelectedElement("selectToDate"));

	let marketConditions = GetControlSelectedElement("selectMarketConditions");
	let datesRange = GetDatesRangeFromMarketConditions(marketConditions);

	ChangeDatesRangeSelectorsVisibility(datesRange[0]);

}

function GetSourceDatesList(accessToken) {
	let endPointUrl = GetEndPointUrl("datesListEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceDatesList Success!");

			const dayjs = require("dayjs");

			var text = data.text.reverse();

			datesList.splice(0, datesList.length);
			chartData.dates.splice(0, chartData.dates.length);
			chart2Data.dates.splice(0, chart2Data.dates.length);

			for (i = 0; i < text.length; i++) {
				var row = text[i];

				let date = dayjs(new Date(row[0]));

				datesList.push(date);
				chartData.dates.push(row[0]);
				chart2Data.dates.push(row[0]);
			}
		})
		.catch(function(error) {
			console.error("GetSourceDatesList error", error);
			throw(error);
		});
}

function GetSourceChartSerieData(accessToken, endpoint, chartDataSeries, scale = 1) {
	return makeRequest(endpoint, accessToken)
		.then(function(data) {
			console.info("GetSourceChartSerieData Success!");

			var text = data.values.reverse();

			chartDataSeries.splice(0, chartDataSeries.length);

			for (i = 0; i < text.length; i++) {
				var row = text[i];
				chartDataSeries.push((parseFloat(row[0])*scale).toFixed(2));
			}
		})
		.catch(function(error) {
			console.error("Something went wrong", error);
			throw(error);
		});
}

function SetControlCustomSeriesTitles() {
	let customSerie1Name = document.getElementById("portfolioNameGlobalStocks").value;
	let customSerie2Name = document.getElementById("portfolioName6040Static").value;
	let customSerie3Name = document.getElementById("portfolioNameTacticalEquties").value;
	let customSerie4Name = document.getElementById("portfolioName8020Tactical").value;

	chartData.titleSerie1 = customSerie1Name;
	chartData.titleSerie2 = customSerie2Name;
	chartData.titleSerie3 = customSerie3Name;
	chartData.titleSerie4 = customSerie4Name;

	chart2Data.titleSerie1 = customSerie1Name;
	chart2Data.titleSerie2 = customSerie2Name;
	chart2Data.titleSerie3 = customSerie3Name;
	chart2Data.titleSerie4 = customSerie4Name;

	document.getElementById("AnnualTableCustomSerie1Title").innerHTML = customSerie1Name;
	document.getElementById("AnnualTableCustomSerie2Title").innerHTML = customSerie2Name;
	document.getElementById("AnnualTableCustomSerie3Title").innerHTML = customSerie3Name;
	document.getElementById("AnnualTableCustomSerie4Title").innerHTML = customSerie4Name;

	document.getElementById("StatisticsTableCustomSerie1Title").innerHTML = customSerie1Name;
	document.getElementById("StatisticsTableCustomSerie2Title").innerHTML = customSerie2Name;
	document.getElementById("StatisticsTableCustomSerie3Title").innerHTML = customSerie3Name;
	document.getElementById("StatisticsTableCustomSerie4Title").innerHTML = customSerie4Name;
}

function RecalculateData() {

	if(datesList.length === 0) {
		console.warn("Empty data, can´t recalculate");
		return;
	}

	console.info("Recalculating...");

	ChangeElementVisibility("LoadSpinner", true);
	ChangeElementVisibility("LoadSpinner2", true);

	DestroySeriesChart("chart1");
	DestroySeriesChart("chart2");

	SetControlCustomSeriesTitles();

	myMSALObj
		.acquireTokenSilent(requestObj)
		.then(function(tokenResponse) {
			PutSourceData(tokenResponse.accessToken);
		})
		.catch(function(error) {
			console.error(error);
			// Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
			// Call acquireTokenPopup(popup window)
			if (requiresInteraction(error.errorCode)) {
				myMSALObj
					.acquireTokenPopup(requestObj)
					.then(function(tokenResponse) {
						PutSourceData(tokenResponse.accessToken);
					})
					.catch(function(error) {
						console.error(error);
					});
			}
		});
}

function GetSubsetChartData(chartData, fromDate, toDate) {

	var subsetData = {
		dates: [],
		dataSeries1: [],
		dataSeries2: [],
		dataSeries3: [],
		dataSeries4: [],
		titleChart: "",
		titleSerie1: "",
		titleSerie2: "",
		titleSerie3: "",
		titleSerie4: "",
		yAxesLabelString: ""
	};

	subsetData.titleChart = chartData.titleChart;
	subsetData.titleSerie1 = chartData.titleSerie1;
	subsetData.titleSerie2 = chartData.titleSerie2;
	subsetData.titleSerie3 = chartData.titleSerie3;
	subsetData.titleSerie4 = chartData.titleSerie4;

	subsetData.yAxesLabelString = chartData.yAxesLabelString;

	for (var i = 0; i < datesList.length; i++) {
		if (datesList[i] >= fromDate && datesList[i] <= toDate) {
			subsetData.dates.push(chartData.dates[i]);
			subsetData.dataSeries1.push(chartData.dataSeries1[i]);
			subsetData.dataSeries2.push(chartData.dataSeries2[i]);
			subsetData.dataSeries3.push(chartData.dataSeries3[i]);
			subsetData.dataSeries4.push(chartData.dataSeries4[i]);
		}
	}

	return subsetData;
}

function SetControlSelectElement(id, valueToSelect) {
	let element = document.getElementById(id);
	element.value = valueToSelect;
}

function GetControlSelectedElement(id) {
	var selectControl = document.getElementById(id);
	return selectControl.options[selectControl.selectedIndex].value;
}

function GetSourceMarketConditions(accessToken) {
	let endPointUrl = GetEndPointUrl("marketConditionsEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceMarketConditions Success!");
			//console.table(data.values[0]);
			SetControlSelectElement("selectMarketConditions", data.values[0]);
		})
		.catch(function(error) {
			console.error("GetSourceMarketConditions error", error);
			throw(error);
		});
}

function GetSourceMarketExposure(accessToken) {
	let endPointUrl = GetEndPointUrl("marketExposureEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceMarketExposure Success!");
			//console.table(data.values);
			SetMarketExposure(data.values);
		})
		.catch(function(error) {
			console.error("GetSourceMarketExposure error", error);
			throw(error);
		});
}

function SetMarketExposure(values) {
	SetControlSelectElement("marketExposureGlobalStocks", values[0][0]);
	SetControlSelectElement("marketExposure6040Static", values[0][1]);
	SetControlSelectElement("marketExposureTacticalEquties", values[0][2]);
	SetControlSelectElement("marketExposure8020Tactical", values[0][3]);
}

function GetSourceStartingBalance(accessToken) {
	let endPointUrl = GetEndPointUrl("startingBalanceEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceStartingBalance Success!");
			//console.log(data.values[0][0]);
			SetControlIntegerValue(startingBalanceControl, data.values[0][0]);
		})
		.catch(function(error) {
			console.error("GetSourceStartingBalance error", error);
			throw(error);
		});
}

function PatchSourceDatesRange(accessToken) {

	let dates = GetSelectedDates();

	var params = {
		values: [[dates[0]], [dates[1]]]
	};

	let endPointUrl = GetEndPointUrl("datesEndpoint");
	return makeRequest(endPointUrl, accessToken, "PATCH", params)
		.then(function(data) {
			console.info("PatchSourceDatesRange Success!");
			//console.info(params.values);
		})
		.catch(function(error) {
			console.error("PatchSourceDatesRange error", error);
			throw(error);
		});
}

function PatchSourceMarketConditions(accessToken) {
	let marketConditions = GetControlSelectedElement("selectMarketConditions");
	var params = {
		values: [[marketConditions]]
	};

	let endPointUrl = GetEndPointUrl("marketConditionsEndpoint");
	return makeRequest(endPointUrl, accessToken, "PATCH", params)
		.then(function(data) {
			console.info("PatchSourceMarketConditions Success!");
			//console.log(params);
		})
		.catch(function(error) {
			console.error("PatchSourceMarketConditions error", error);
			throw(error);
		});
}

function PatchSourceStartingBalance(accessToken) {
	var startingBalance = GetControlIntegerValue(startingBalanceControl);

	//console.log("Starting balance: " + startingBalance);

	var params = {
		values: [[startingBalance, startingBalance, startingBalance, startingBalance]]
	};

	let endPointUrl = GetEndPointUrl("startingBalanceEndpoint");
	return makeRequest(endPointUrl, accessToken, "PATCH", params)
		.then(function(data) {
			console.info("PatchSourceStartingBalance Success!");
		})
		.catch(function(error) {
			console.error("PatchSourceStartingBalance error", error);
			throw(error);
		});
}

function FloatToPercentage(value, precision) {
	var percent = parseFloat(value) * 100.0;
	return percent.toFixed(precision);
}

function FloatToCurrency(value, precision) {
	var currency = parseFloat(value);
	var formated = OSREC.CurrencyFormatter.format(currency, { currency: 'USD', pattern: '! #,##0;-!#,##0', valueOnError: 'Error!' });

	return formated;
}

function PatchSourceSettings(accessToken) {

	var settingsValues = GetSettings();

	var params = {
		values: settingsValues
	};

	//console.warn("PatchSettings");
	//console.table(params);

	let endPointUrl = GetEndPointUrl("settingsEndpoint");
	return makeRequest(endPointUrl, accessToken, "PATCH", params)
		.then(function(data) {
			console.info("PatchSourceSettings Success!");
			DrawPieCharts();
		})
		.catch(function(error) {
			console.error("PatchSourceSettings error", error);
			throw(error);
		});
}

function GetSettings() {

	var cashFlowGlobalStocks = GetControlPercentageValue(cashFlowGlobalStocksControl);
	var cashFlow6040Static = GetControlPercentageValue(cashFlow6040StaticControl);
	var cashFlowTacticalEquties = GetControlPercentageValue(cashFlowTacticalEqutiesControl);
	var cashFlow8020Tactical = GetControlPercentageValue(cashFlow8020TacticalControl);

	var inflationGlobalStocks = GetControlPercentageValue(inflationGlobalStocksControl);
	var inflation6040Static = GetControlPercentageValue(inflation6040StaticControl);
	var inflationTacticalEquties = GetControlPercentageValue(inflationTacticalEqutiesControl);
	var inflation8020Tactical = GetControlPercentageValue(inflation8020TacticalControl);

	var globalStockGlobalStocks = GetControlIntegerValue(globalStockGlobalStocksControl);
	var globalStock6040Static = GetControlIntegerValue(globalStock6040StaticControl);
	var globalStockTacticalEquties = GetControlIntegerValue(globalStockTacticalEqutiesControl);
	var globalStock8020Tactical = GetControlIntegerValue(globalStock8020TacticalControl);

	var bondGlobalStocks = GetControlIntegerValue(bondGlobalStocksControl);
	var bond6040Static = GetControlIntegerValue(bond6040StaticControl);
	var bondTacticalEquties = GetControlIntegerValue(bondTacticalEqutiesControl);
	var bond8020Tactical = GetControlIntegerValue(bond8020TacticalControl);

	return [[cashFlowGlobalStocks, cashFlow6040Static, cashFlowTacticalEquties, cashFlow8020Tactical],
		[inflationGlobalStocks, inflation6040Static, inflationTacticalEquties, inflation8020Tactical],
		[globalStockGlobalStocks, globalStock6040Static, globalStockTacticalEquties, globalStock8020Tactical],
		[bondGlobalStocks, bond6040Static, bondTacticalEquties, bond8020Tactical]];

}

function PatchSourceMarketExposure(accessToken) {

	console.info("PatchSourceMarketExposure");

	var marketExposureValues = GetMarketExposure();

	var params = {
		values: marketExposureValues
	};

	//console.table(params);

	let endPointUrl = GetEndPointUrl("marketExposureEndpoint");
	return makeRequest(endPointUrl, accessToken, "PATCH", params)
		.then(function(data) {
			console.info("PatchSourceMarketExposure Success!");
		})
		.catch(function(error) {
			console.error("PatchSourceMarketExposure error", error);
			throw(error);
		});
}

function GetMarketExposure() {

	var marketExposureGlobalStocks = document.getElementById("marketExposureGlobalStocks").value;
	var marketExposure6040Static = document.getElementById("marketExposure6040Static").value;
	var marketExposureTacticalEquties = document.getElementById("marketExposureTacticalEquties").value;
	var marketExposure8020Tactical = document.getElementById("marketExposure8020Tactical").value;

	return [[marketExposureGlobalStocks,
			 marketExposure6040Static,
			 marketExposureTacticalEquties,
			 marketExposure8020Tactical]];

}

function GetSourceSettings(accessToken) {
	let endPointUrl = GetEndPointUrl("settingsEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceSettings Success!");
			//console.table(data.values);

			SetSettings(data.values);

			DrawPieCharts();
		})
		.catch(function(error) {
			console.error("GetSourceSettings error", error);
			throw(error);
		});
}

function SetSettings(values) {
	SetControlPercentageValue(cashFlowGlobalStocksControl, values[0][0]);
	SetControlPercentageValue(cashFlow6040StaticControl, values[0][1]);
	SetControlPercentageValue(cashFlowTacticalEqutiesControl, values[0][2]);
	SetControlPercentageValue(cashFlow8020TacticalControl, values[0][3]);

	SetControlPercentageValue(inflationGlobalStocksControl, values[1][0]);
	SetControlPercentageValue(inflation6040StaticControl, values[1][1]);
	SetControlPercentageValue(inflationTacticalEqutiesControl, values[1][2]);
	SetControlPercentageValue(inflation8020TacticalControl, values[1][3]);

	SetControlIntegerValue(globalStockGlobalStocksControl, values[2][0]);
	SetControlIntegerValue(globalStock6040StaticControl, values[2][1]);
	SetControlIntegerValue(globalStockTacticalEqutiesControl, values[2][2]);
	SetControlIntegerValue(globalStock8020TacticalControl, values[2][3]);

	SetControlIntegerValue(bondGlobalStocksControl, values[3][0]);
	SetControlIntegerValue(bond6040StaticControl, values[3][1]);
	SetControlIntegerValue(bondTacticalEqutiesControl, values[3][2]);
	SetControlIntegerValue(bond8020TacticalControl, values[3][3]);
}

function Sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function PutSourceData(accessToken) {
	console.info("PutSourceData");

	var arrayOfPromises = [];

	arrayOfPromises.push(PatchSourceMarketConditions(accessToken));
	arrayOfPromises.push(PatchSourceDatesRange(accessToken));
	arrayOfPromises.push(PatchSourceStartingBalance(accessToken));
	arrayOfPromises.push(PatchSourceSettings(accessToken));
	arrayOfPromises.push(PatchSourceMarketExposure(accessToken));
	arrayOfPromises.push(Sleep(0));

	Promise.all(arrayOfPromises).then(values => {
		console.info("PutSourceData promise all finish");
		GetSourceData(accessToken, "UPDATE");
	}).catch(reason => { 
		ShowError(reason);
	});
}

function GetSourceDatesRange(accessToken) {
	let endPointUrl = GetEndPointUrl("datesEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceDatesRange Success!");

			const dayjs = require("dayjs");

			dataDateFrom = dayjs(new Date(data.text[0][0]));
			dataDateTo = dayjs(new Date(data.text[1][0]));

			console.info("From " + dataDateFrom.format(DATA_FORMAT) + " To " + dataDateTo.format(DATA_FORMAT));
		})
		.catch(function(error) {
			console.error("GetSourceDatesRange error", error);
			throw(error);
		});
}

function PopulateTableStatistics(){

	DeleteTable("TableStatistics", tableStatisticsLength);
	tableStatisticsLength = 0;

	if(statisticsData !== null) {

		TableStatisticsAddPercentageRow("Standard Deviation", statisticsData[0], 0);
		TableStatisticsAddPercentageRow("Upside Capture", statisticsData[2], 0);
		TableStatisticsAddPercentageRow("Downside Capture", statisticsData[3], 0);
		TableStatisticsAddPercentageRow("Max Drawdown %", statisticsData[4], 0);

		let portfolioStatisticsSwitch = GetControlCheckedValue("portfolioStatisticsSwitch");

		if(portfolioStatisticsSwitch) {
			TableStatisticsAddCurrenciesRow("Max Drawdown $", maxDrawdownData[0]);
			TableStatisticsAddPercentageRow("CAGR", statisticsData[5], 1);
			TableStatisticsAddCurrenciesRow("Ending Value", statisticsData[6]);
		}

		let index;
		for (index = 0; index < 4; index++) {
			ChangeTableColumnVisibility("TableStatistics", index+1, toggleState.series[index]);
		}
	}
}

function GetSourceStatistics(accessToken) {
	let endPointUrl = GetEndPointUrl("statisticsEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceStatistics Success!");
			//console.table(data.values);

			statisticsData = data.values;
		})
		.catch(function(error) {
			console.error("GetSourceStatistics error", error);
			throw(error);
		});
}

function GetSourceMaxDrawdown(accessToken) {
	let endPointUrl = GetEndPointUrl("maxDrawdownEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceMaxDrawdown Success!");
			//console.table(data.values);

			maxDrawdownData = data.values;

		})
		.catch(function(error) {
			console.error("GetSourceMaxDrawdown error", error);
			throw(error);
		});
}

function GetSourceYearlySeries(accessToken) {
	let endPointUrl = GetEndPointUrl("yearlySeriesEndpoint");
	return makeRequest(endPointUrl, accessToken)
		.then(function(data) {
			console.info("GetSourceYearlySeries Success!");

			//console.table(data.values);
			DeleteTable("TableAnnual", annualReturnsLength);
			annualReturnsLength = 0;
			PopulateTableAnnual(data.values);
		})
		.catch(function(error) {
			console.error("GetSourceYearlySeries error", error);
			throw(error);
		});
}

function GetControlCheckedValue(checkboxId) {
	let checkbox = document.querySelector(`#${checkboxId}`);
	return checkbox.checked;
}

function CheckboxControlClick(checkboxId) {
	document.querySelector(`#${checkboxId}`).click();
}

function TableStatisticsAddPercentageRow(label, values, precision) {

	var table = document.getElementById("TableStatistics");

	var row = table.insertRow(-1);
	var cell1 = row.insertCell(0);
	var cell2 = row.insertCell(1);
	var cell3 = row.insertCell(2);
	var cell4 = row.insertCell(3);
	var cell5 = row.insertCell(4);

	cell1.innerHTML = label;
	cell1.className = "FirstCell";
	cell2.innerHTML = FloatToPercentage(values[0], precision) + " %";
	cell3.innerHTML = FloatToPercentage(values[1], precision) + " %";
	cell4.innerHTML = FloatToPercentage(values[2], precision) + " %";
	cell5.innerHTML = FloatToPercentage(values[3], precision) + " %";
	cell5.className = "FinalCell ";

	tableStatisticsLength = tableStatisticsLength+1;
}

function TableStatisticsAddCurrenciesRow(label, values) {

	var table = document.getElementById("TableStatistics");

	var row = table.insertRow(-1);
	var cell1 = row.insertCell(0);
	var cell2 = row.insertCell(1);
	var cell3 = row.insertCell(2);
	var cell4 = row.insertCell(3);
	var cell5 = row.insertCell(4);

	cell1.innerHTML = label;
	cell1.className = "FirstCell";
	cell2.innerHTML = FloatToCurrency(values[0], 0);
	cell3.innerHTML = FloatToCurrency(values[1], 0);
	cell4.innerHTML = FloatToCurrency(values[2], 0);
	cell5.innerHTML = FloatToCurrency(values[3], 0);
	cell5.className = "FinalCell ";

	tableStatisticsLength = tableStatisticsLength+1;
}


function PopulateTableAnnual(values) {
	console.info("Populating table");

	var table = document.getElementById("TableAnnual");

	for (i = 0; i < values.length; i++) {
		var row = table.insertRow(-1);
		var cell1 = row.insertCell(0);
		var cell2 = row.insertCell(1);
		var cell3 = row.insertCell(2);
		var cell4 = row.insertCell(3);
		var cell5 = row.insertCell(4);

		cell1.innerHTML = values[i][0];
		cell1.className = "FirstCell";
		cell2.innerHTML = FloatToPercentage(values[i][2], 0) + " %";
		cell3.innerHTML = FloatToPercentage(values[i][3], 0) + " %";
		cell4.innerHTML = FloatToPercentage(values[i][4], 0) + " %";
		cell5.innerHTML = FloatToPercentage(values[i][5], 0) + " %";
		cell5.className = "FinalCell ";
	}

	annualReturnsLength = values.length;
}

function DeleteTable(tableId, rows) {
	console.info("Deleting table " + tableId + " rows " + rows);

	var table = document.getElementById(tableId);

	for (i = 0; i < rows; i++) {
		table.deleteRow(1);
	}
}

function createSession(accessToken) {
	let endPointUrl =  GetEndPointUrl("createSessionEndpoint");
	var params = { persistChanges: false };
	return makeRequest(endPointUrl, accessToken, "POST", params)
		.then(function(data) {
			console.info("CreateSession Success!");
			sessionId = data.id;
		})
		.catch(function(error) {
			console.error("CreateSession error", error);
		});
}

function GetSourceExcelSettings(accessToken) {

	return makeRequest(excelSettingsEndpoint, accessToken)
		.then(function(data) {
			console.info("GetExcelSettings Success!");
			SetExcelSettings(data.text);
		})
		.catch(function(error) {
			console.error("GetExcelSettings error", error);
			throw(error);
		});
}

function SetExcelSettings(settings) {
	console.info("Set excel settings");
	console.table(settings);

	graphVariables.DATA_SHEET_ID = settings[0][1];

	graphVariables.marketConditionsSheet = settings[1][1];
	graphVariables.marketConditionsRange = settings[1][2];
	graphVariables.datesSheet = settings[2][1];
	graphVariables.datesRange = settings[2][2];

	graphVariables.startingBalanceSheet = settings[3][1];
	graphVariables.startingBalanceRange = settings[3][2];

	graphVariables.settingsSheet = settings[4][1];
	graphVariables.settingsRange = settings[4][2];

	graphVariables.cashflowSheet = settings[5][1];
	graphVariables.cashflowRange = settings[5][2];

	graphVariables.inflationSheet = settings[6][1];
	graphVariables.inflationRange = settings[6][2];

	graphVariables.marketExposureSheet = settings[7][1];
	graphVariables.marketExposureRange = settings[7][2];

	graphVariables.yearlySeriesSheet = settings[8][1];
	graphVariables.yearlySeriesRange = settings[8][2];

	graphVariables.statisticsSheet = settings[9][1];
	graphVariables.statisticsRange = settings[9][2];

	graphVariables.maxDrawdownSheet = settings[10][1];
	graphVariables.maxDrawdownRange = settings[10][2];

	graphVariables.datesListSheet = settings[11][1];
	graphVariables.datesListRange = settings[11][2];

	graphVariables.chart1Serie1Sheet = settings[12][1];
	graphVariables.chart1Serie1Range = settings[12][2];

	graphVariables.chart1Serie2Sheet = settings[13][1];
	graphVariables.chart1Serie2Range = settings[13][2];

	graphVariables.chart1Serie3Sheet = settings[14][1];
	graphVariables.chart1Serie3Range = settings[14][2];

	graphVariables.chart1Serie4Sheet = settings[15][1];
	graphVariables.chart1Serie4Range = settings[15][2];

	graphVariables.chart2Serie1Sheet = settings[16][1];
	graphVariables.chart2Serie1Range = settings[16][2];

	graphVariables.chart2Serie2Sheet = settings[17][1];
	graphVariables.chart2Serie2Range = settings[17][2];

	graphVariables.chart2Serie3Sheet = settings[18][1];
	graphVariables.chart2Serie3Range = settings[18][2];

	graphVariables.chart2Serie4Sheet = settings[19][1];
	graphVariables.chart2Serie4Range = settings[19][2];

	/*graphVariables.chart2SeriesSheet = settings[16][1];
	graphVariables.chart2SeriesRange = settings[16][2];*/

}

async function GetSourceData(accessToken, kind) {

	if (typeof sessionId == "undefined") {
		console.info("Getting excel settings");

		await GetSourceExcelSettings(accessToken).then();

		console.info("Creating session");

		await createSession(accessToken).then();
	}

	console.info("Getting sheet data");

	var arrayOfPromises = [];

	arrayOfPromises.push(GetSourceDatesRange(accessToken));

	if (kind != "UPDATE") {
		arrayOfPromises.push(GetSourceDatesList(accessToken));
		arrayOfPromises.push(GetSourceMarketConditions(accessToken));
		arrayOfPromises.push(GetSourceStartingBalance(accessToken));
		arrayOfPromises.push(GetSourceMarketExposure(accessToken));
		arrayOfPromises.push(GetSourceSettings(accessToken));
	}

	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart1Serie1Endpoint"), chartData.dataSeries1));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart1Serie2Endpoint"), chartData.dataSeries2));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart1Serie3Endpoint"), chartData.dataSeries3));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart1Serie4Endpoint"), chartData.dataSeries4));

	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart2Serie1Endpoint"), chart2Data.dataSeries1, 100));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart2Serie2Endpoint"), chart2Data.dataSeries2, 100));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart2Serie3Endpoint"), chart2Data.dataSeries3, 100));
	arrayOfPromises.push(GetSourceChartSerieData(accessToken, GetEndPointUrl("chart2Serie4Endpoint"), chart2Data.dataSeries4, 100));

	arrayOfPromises.push(GetSourceStatistics(accessToken));
	arrayOfPromises.push(GetSourceMaxDrawdown(accessToken));
	arrayOfPromises.push(GetSourceYearlySeries(accessToken));

	Promise.all(arrayOfPromises)
	.then(values => {
		console.info("GetSourceData Promise all success");

		if (kind !== "UPDATE") {
			PopulateDatesControls();
		}

		PopulateTableStatistics();

		DrawSeriesCharts();

		ChangeElementVisibility("LoadSpinner", false);
		ChangeElementVisibility("LoadSpinner2", false);

	}).catch(reason => {
		ShowError(reason);
	});
}

function DrawSeriesCharts() {
	chartDataSubSet = GetSubsetChartData(chartData, dataDateFrom, dataDateTo);
	chart2DataSubSet = GetSubsetChartData(chart2Data, dataDateFrom, dataDateTo);

	DrawSeriesChart("chart1", chartDataSubSet);
	DrawSeriesChart("chart2", chart2DataSubSet);

	ToggleChartSeries("chart1");
	ToggleChartSeries("chart2");
}

function CheckPieChartTotal(globalId, bondId) {

	var globalIdValue = document.getElementById(globalId).value * 1.0;
	var bondIdValue = document.getElementById(bondId).value * 1.0;

	let total = globalIdValue + bondIdValue;
	if (total !== 100) {
		AddClassToElement(globalId, "red");
		AddClassToElement(bondId, "red");
	}
	else {
		RemoveClassFromElement(globalId, "red");
		RemoveClassFromElement(bondId, "red");
	}

	return (total === 100);
}


function CheckPieChartTotalOnChange(globalId, bondId) {
	  let globalIdField = document.querySelector(`#${globalId}`);
	  let bondIdField = document.querySelector(`#${bondId}`);
	  globalIdField.addEventListener("change", function() {
		    CheckPieChartTotal(globalId, bondId);
	  });
	  bondIdField.addEventListener("change", function() {
		    CheckPieChartTotal(globalId, bondId);
	  });
}

function DrawPieCharts() {
	var globalStockGlobalStocks = document.getElementById("globalStockGlobalStocks").value * 1.0;
	var globalStock6040Static = document.getElementById("globalStock6040Static").value * 1.0;
	var globalStockTacticalEquties = document.getElementById("globalStockTacticalEquties").value * 1.0;
	var globalStock8020Tactical = document.getElementById("globalStock8020Tactical").value * 1.0;

	var bondGlobalStocks = document.getElementById("bondGlobalStocks").value * 1.0;
	var bond6040Static = document.getElementById("bond6040Static").value * 1.0;
	var bondTacticalEquties = document.getElementById("bondTacticalEquties").value * 1.0;
	var bond8020Tactical = document.getElementById("bond8020Tactical").value * 1.0;

	CheckPieChartTotal("globalStockGlobalStocks", "bondGlobalStocks");
	CheckPieChartTotal("globalStock6040Static", "bond6040Static");
	CheckPieChartTotal("globalStockTacticalEquties", "bondTacticalEquties");
	CheckPieChartTotal("globalStock8020Tactical", "bond8020Tactical");

	DrawPieChart("pieChart1", [globalStockGlobalStocks, bondGlobalStocks]);
	DrawPieChart("pieChart2", [globalStock6040Static, bond6040Static]);
	DrawPieChart("pieChart3", [globalStockTacticalEquties, bondTacticalEquties]);
	DrawPieChart("pieChart4", [globalStock8020Tactical, bond8020Tactical]);

}

var myCharts = new Array(2);
var myPieCharts = new Map();

function DestroyPieChart(chartId) {
	let chart = myPieCharts.get(chartId);

	if (chart !== undefined) {
		chart.destroy();
	}
}

function DestroySeriesChart(chartId) {
	var index = 0;
	if (chartId === "chart2") index = 1;

	if (myCharts[index] != null) {
		myCharts[index].destroy();
	}
}

function DrawPieChart(chartId, data) {
	var ctx = document.getElementById(chartId).getContext("2d");

	var config = {
		type: "pie",
		data: {
			datasets: [
				{
					data: [data[0], data[1]],
					backgroundColor: [colors.pieChart1, colors.pieChart2],
					label: "Dataset 1"
				}
			],
			labels: ["Global Stock %", "Bond %"]
		},
		options: {
			legend: {
				display: false
			},
			responsive: true,
			animation: false
		}
	};

	let pieChart = new Chart(ctx, config);
	myPieCharts.set(chartId, pieChart);
}

function ToggleChartSeries(chartId) {
	var index = 0;
	if (chartId === "chart2") index = 1;

	let globalStocksSwitchEnabled = GetControlCheckedValue("globalStocksSwitch");
	let id6040StaticSwitchEnabled = GetControlCheckedValue("id6040StaticSwitch");
	let tacticalEqutiesSwitch = GetControlCheckedValue("tacticalEqutiesSwitch");
	let id8020TacticalSwitch = GetControlCheckedValue("id8020TacticalSwitch");

	if (!globalStocksSwitchEnabled) ToggleChartSerie(myCharts[index], 0);
	if (!id6040StaticSwitchEnabled) ToggleChartSerie(myCharts[index], 1);
	if (!tacticalEqutiesSwitch) ToggleChartSerie(myCharts[index], 2);
	if (!id8020TacticalSwitch) ToggleChartSerie(myCharts[index], 3);
}

Chart.Tooltip.positioners.custom = function(elements, eventPosition) {
	var tooltip = this;

	return {
		x: 0+200,
		y: 0+10
	};
};

function DrawSeriesChart(chartId, data) {
	var ctx = document.getElementById(chartId).getContext("2d");

	var index = 0;
	if (chartId === "chart2") index = 1;

	myCharts[index] = new Chart(ctx, {
		type: "line",

		data: {
			labels: data.dates,
			datasets: [
				{
					label: data.titleSerie1,
					fill: false,
					lineTension: 0,
					backgroundColor: colors.lineChart1,
					borderColor: colors.lineChart1,
					data: data.dataSeries1,
					pointRadius: 1
				},
				{
					label: data.titleSerie2,
					fill: false,
					lineTension: 0,
					backgroundColor: colors.lineChart2,
					borderColor: colors.lineChart2,
					data: data.dataSeries2,
					pointRadius: 1
				},
				{
					label: data.titleSerie3,
					fill: false,
					lineTension: 0,
					backgroundColor: colors.lineChart3,
					borderColor: colors.lineChart3,
					data: data.dataSeries3,
					pointRadius: 1
				},
				{
					label: data.titleSerie4,
					fill: false,
					lineTension: 0,
					backgroundColor: colors.lineChart4,
					borderColor: colors.lineChart4,
					data: data.dataSeries4,
					pointRadius: 1
				}
			]
		},
		options: {
			legend: {
				display: true,
				position: "right",
				onClick: function(e, legendItem) {
					var index = legendItem.datasetIndex;

					switch (index) {
						case 0:
							CheckboxControlClick("globalStocksSwitch");
							break;
						case 1:
							CheckboxControlClick("id6040StaticSwitch");
							break;
						case 2:
							CheckboxControlClick("tacticalEqutiesSwitch");
							break;
						case 3:
							CheckboxControlClick("id8020TacticalSwitch");
							break;
					  }
				},
				labels: {
					//fontColor: 'rgb(255, 99, 132)'
				}
			},
			responsive: true,
			title: {
				display: false,
				fontSize: 24,
				text: data.titleChart
			},
			tooltips: {
				mode: "index",
				intersect: false,
				titleFontSize: 14,
				bodySpacing : 6,
				bodyAlign : "right",
				caretSize : 0,

				callbacks: {
					label: function(tooltipItem, data) {
						var label = data.datasets[tooltipItem.datasetIndex].label || '';

						if (label) {
							label += ': ';
						}

						if (index === 0) {
							label += FloatToCurrency(Math.round(tooltipItem.yLabel * 100) / 100, 0);
						}
						if (index === 1) {
							label += (Math.round(tooltipItem.yLabel * 100) / 100).toFixed(2);
							label += ' %';
						}
						return label;
					}
				},
				position: 'custom'
			},
			hover: {
				mode: "nearest",
				intersect: true
			},
			scales: {
				xAxes: [
					{
						display: true,
						scaleLabel: {
							display: false,
							labelString: "Month"
						}
					}
				],
				yAxes: [
					{
						display: true,
						scaleLabel: {
							display: true,
							labelString: data.yAxesLabelString
						},
						ticks: {
							callback: function(value, tickindex, values) {
								if (index === 0) {
									return FloatToCurrency(value, 0);
								}

								if (index === 1) {
									return value + " %";
								}
							}
						}
					}
				]
			}
		}
	});
}

/* PROMISES */

var makeRequest = function(url, accessToken, method, body) {

	var xmlHttp = new XMLHttpRequest();

	// Return it as a Promise
	return new Promise(function(resolve, reject) {
		// Setup our listener to process compeleted requests
		xmlHttp.onreadystatechange = function() {
			// Only run if the request is complete
			if (xmlHttp.readyState !== 4) return;

			// Process the response
			if (xmlHttp.status == 200 || xmlHttp.status == 201) {
				// If successful
				resolve(JSON.parse(xmlHttp.responseText));
			} else {
				// If failed
				reject({
					status: xmlHttp.status,
					statusText: xmlHttp.statusText
				});
			}
		};

		xmlHttp.open(method || "GET", url, true); // true for asynchronous
		xmlHttp.setRequestHeader("Authorization", "Bearer " + accessToken);

		if (typeof sessionId !== "undefined") {
			xmlHttp.setRequestHeader("workbook-session-id", sessionId);
		}

		if (body) {
			xmlHttp.send(JSON.stringify(body));
		} else {
			xmlHttp.send();
		}
	});
};

function ChangeElementVisibility(id, visible) {
	var elem = document.getElementById(id);
	if (visible) elem.style.removeProperty("display");
	else elem.style.display = "none";
}

function AddClassToElement(id, className) {
	var elem = document.getElementById(id);
	elem.classList.add(className);
}

function RemoveClassFromElement(id, className) {
	var elem = document.getElementById(id);
	elem.classList.remove(className);
}

function MakeElementTogglable(checkboxId, elementId) {
	let checkbox = document.querySelector(`#${checkboxId}`);
	checkbox.addEventListener("change", function() {
		ChangeElementVisibility(elementId, this.checked);
	});
}

function MakeChartSerieToggleable(checkboxId, index) {
	let checkbox = document.querySelector(`#${checkboxId}`);
	checkbox.addEventListener("change", function() {
		ToggleSerie(index)
	});
}

function ToggleSerie(index) {
	toggleState.series[index] = !toggleState.series[index];
	ToggleChartSerie(myCharts[0], index);
	ToggleChartSerie(myCharts[1], index);
}

function MakeElementExecuteOnToggle(checkboxId, action) {
	let checkbox = document.querySelector(`#${checkboxId}`);
	checkbox.addEventListener("change", action);
}

function ToggleChartSerie(chart, index) {
	[chart.getDatasetMeta(index)].forEach(function(meta) {
		meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
		let visible = meta.hidden === null;
		ChangeTableColumnVisibility("TableAnnual", index + 1, visible);
		ChangeTableColumnVisibility("TableStatistics", index + 1, visible);
	});
	chart.update();
}

function ChangeTableColumnVisibility(tableId, column, visible) {
	var tbl = document.getElementById(tableId);
	var rows = tbl.getElementsByTagName("tr");

	for (var row = 0; row < rows.length; row++) {
		var cols = rows[row].children;
		if (column >= 0 && column < cols.length) {
			var cell = cols[column];
			if (cell.tagName == "TD" || cell.tagName == "TH")
				if (visible) cell.className = "";
				else cell.className = "hidden";
		}
	}
}

function beforePrintHandler () {
    for (var id in Chart.instances) {
        Chart.instances[id].resize();
    }
}

function ShowError(reason) {
	ChangeElementVisibility("alertMessage", true);
}

document.addEventListener("DOMContentLoaded", function(event) {
	MakeElementTogglable("portfolioConstructionSwitch", "portfolioConstructionContainerToggleable");
	MakeElementExecuteOnToggle("portfolioStatisticsSwitch", PopulateTableStatistics);
	MakeElementTogglable("growthChartSwitch", "growthChartContainer");
	MakeElementTogglable("drawdownChartSwitch", "drawdownChartContainer");
	MakeElementTogglable("annualReturnsTableSwitch", "annualReturnsTableContainer");

	MakeChartSerieToggleable("globalStocksSwitch", 0);
	MakeChartSerieToggleable("id6040StaticSwitch", 1);
	MakeChartSerieToggleable("tacticalEqutiesSwitch", 2);
	MakeChartSerieToggleable("id8020TacticalSwitch", 3);

	MakeElementTogglable("globalStocksSwitch", "marketExposureGlobalStocks");
	MakeElementTogglable("globalStocksSwitch", "cashFlowGlobalStocksGroup");
	MakeElementTogglable("globalStocksSwitch", "inflationGlobalStocksGroup");
	MakeElementTogglable("globalStocksSwitch", "globalStockGlobalStocksGroup");
	MakeElementTogglable("globalStocksSwitch", "bondGlobalStocksGroup");

	MakeElementTogglable("id6040StaticSwitch", "marketExposure6040Static");
	MakeElementTogglable("id6040StaticSwitch", "cashFlow6040StaticGroup");
	MakeElementTogglable("id6040StaticSwitch", "inflation6040StaticGroup");
	MakeElementTogglable("id6040StaticSwitch", "globalStock6040StaticGroup");
	MakeElementTogglable("id6040StaticSwitch", "bond6040StaticGroup");

	MakeElementTogglable("tacticalEqutiesSwitch", "marketExposureTacticalEquties");
	MakeElementTogglable("tacticalEqutiesSwitch", "cashFlowTacticalEqutiesGroup");
	MakeElementTogglable("tacticalEqutiesSwitch", "inflationTacticalEqutiesGroup");
	MakeElementTogglable("tacticalEqutiesSwitch", "globalStockTacticalEqutiesGroup");
	MakeElementTogglable("tacticalEqutiesSwitch", "bondTacticalEqutiesGroup");

	MakeElementTogglable("id8020TacticalSwitch", "marketExposure8020Tactical");
	MakeElementTogglable("id8020TacticalSwitch", "cashFlow8020TacticalGroup");
	MakeElementTogglable("id8020TacticalSwitch", "inflation8020TacticalGroup");
	MakeElementTogglable("id8020TacticalSwitch", "globalStock8020TacticalGroup");
	MakeElementTogglable("id8020TacticalSwitch", "bond8020TacticalGroup");

	MakeElementTogglable("globalStocksSwitch", "pieChart1");
	MakeElementTogglable("id6040StaticSwitch", "pieChart2");
	MakeElementTogglable("tacticalEqutiesSwitch", "pieChart3");
	MakeElementTogglable("id8020TacticalSwitch", "pieChart4");

	CheckPieChartTotalOnChange("globalStockGlobalStocks", "bondGlobalStocks");
	CheckPieChartTotalOnChange("globalStock6040Static", "bond6040Static");
	CheckPieChartTotalOnChange("globalStockTacticalEquties", "bondTacticalEquties");
	CheckPieChartTotalOnChange("globalStock8020Tactical", "bond8020Tactical");

	window.onbeforeprint = beforePrintHandler;

 	let printButton = document.querySelector('#PrintButton');

  printButton.addEventListener("click", function(e){
    window.print();
  });
});

function DefineInputIntegerControl(id) {
	return new AutoNumeric(id, {
			decimalPlaces: 0
		});
}


function DefineInputPercentageControl(id) {
	return new AutoNumeric(id, {
			decimalPlaces: 1,
			rawValueDivisor : 100
		});
}

function DefineInputPercentageIntegerControl(id) {
	return new AutoNumeric(id, {
			decimalPlaces: 0,
			rawValueDivisor : 100
		});
}

function DefineInputDateControl(id) {
	return new Cleave(id, {
		date: true,
    	delimiter: '/',
    	datePattern: ['m', 'd', 'Y']
	});
}

function GetControlPercentageValue(control) {
	return control.get() * 1.0;
}

function SetControlPercentageValue(control, value) {
	control.set(value);
}

function GetControlIntegerValue(control) {
	return control.get() * 1.0;
}

function SetControlIntegerValue(control, value) {
	control.set(value);
}


function SelectMarketConditionsChange() {

	let marketConditions = GetControlSelectedElement("selectMarketConditions");
	let datesRange = GetDatesRangeFromMarketConditions(marketConditions);

	console.info(marketConditions);

	UpdateDatesRangeSelectors(datesRange);

	if (datesRange[1] == RECALCULATE ) {
		RecalculateData();
	}	
}

function GetDatesRangeFromMarketConditions(marketConditions) {

	let dates = null;

	const dayjs = require("dayjs");

	switch (marketConditions) {
		case "Bull or Bear":
		  dates = [ DATES_SELECT_GROUP, NOT_RECALCULATE, null, null ];
		  break;
		case "Bull Market ('95 to '99)":
		  dates = [	DATES_SELECT_GROUP, RECALCULATE,
			  		dayjs(new Date("1994/12/31")).format(DATA_FORMAT),
		  			dayjs(new Date("1999/12/31")).format(DATA_FORMAT)];
		  break;
		case "Bear Market ('00 to '09)":
		  dates = [	DATES_SELECT_GROUP, RECALCULATE,
			  		dayjs(new Date("1999/12/31")).format(DATA_FORMAT),
					dayjs(new Date("2009/12/31")).format(DATA_FORMAT)];
		  break;
		case "Bull Market ('10 to '17)":
		  dates = [	DATES_SELECT_GROUP, RECALCULATE,
			  		dayjs(new Date("2009/12/31")).format(DATA_FORMAT),
					dayjs(new Date("2017/12/31")).format(DATA_FORMAT)];
		  break;
		case "Since 2000":
		  dates = [	DATES_SELECT_GROUP, RECALCULATE,
			  		dayjs(new Date("1999/12/31")).format(DATA_FORMAT),
		  			datesList[datesList.length-1].format(DATA_FORMAT) ];
		  break;
		case "Full Date Range":
		  dates = [	DATES_SELECT_GROUP, RECALCULATE,
			  		datesList[0].format(DATA_FORMAT),
					datesList[datesList.length-1].format(DATA_FORMAT) ];
		  break;
		default: //"Custom Date Range"
		  dates = [ DATES_CUSTOM_GROUP, NOT_RECALCULATE, null, null ];
	  }

	  return dates;
}

function ChangeDatesRangeSelectorsVisibility(group) {

	ChangeElementVisibility("selectFromDate", group == DATES_SELECT_GROUP);
	ChangeElementVisibility("selectToDate", group == DATES_SELECT_GROUP);
	ChangeElementVisibility("selectFromDateEditable", group == DATES_CUSTOM_GROUP);
	ChangeElementVisibility("selectToDateEditable", group == DATES_CUSTOM_GROUP);

}

function UpdateDatesRangeSelectors(datesRange) {

	ChangeDatesRangeSelectorsVisibility(datesRange[0]);

	if(datesRange[0] == DATES_SELECT_GROUP) {
		if ( datesRange[2] != null && datesRange[2] != undefined) {
			SetControlSelectElement("selectFromDate", datesRange[2]);
		}
		if ( datesRange[3] != null && datesRange[3] != undefined) {
			SetControlSelectElement("selectToDate", datesRange[3]);
		}
	}

	if(datesRange[0] == DATES_CUSTOM_GROUP) {
		selectFromDateEditableControl.setRawValue('');
		selectToDateEditableControl.setRawValue('');
	}

}

function GetSelectedDates() {
	let marketConditions = GetControlSelectedElement("selectMarketConditions");
	let datesRange = GetDatesRangeFromMarketConditions(marketConditions);

	if(datesRange[0] == DATES_SELECT_GROUP) {
		let fromDate = GetControlSelectedElement("selectFromDate");
		let toDate = GetControlSelectedElement("selectToDate");

		return [fromDate, toDate];
	}

	if(datesRange[0] == DATES_CUSTOM_GROUP) {
		let fromDate = selectFromDateEditableControl.getFormattedValue();
		let toDate = selectToDateEditableControl.getFormattedValue();

		return [fromDate, toDate];
	}
}
